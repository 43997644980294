import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import { useFormContext, Controller } from "react-hook-form";
import OfferMaskField from "../OfferMaskField";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({}));

function PhoneFormatCustom(props) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
            format={(val)=>{
                if (val.startsWith("+7")) val.replace("+7","8")
                if (!val.startsWith("8")) val = `8${val}`

                let code1 = val.substring(0, 1);
                let code2 = val.substring(1, 4);
                let code3 = val.substring(4, 7);
                let code4 = val.substring(7, 9);
                let code5 = val.substring(9, 11);
                return code1?`${code1}(${code2.length==3?`${code2})${code3.length==3?`${code3}-${code4.length==2?`${code4}-${code5}`:code4}`:code3}`:code2}`:""
            }}
			// format="#(###)###-##-##"
            placeholder="8(___)___-__-__"
            // isAllowed={(inputObj)=>{
            //     let { value } = inputObj;
            //     if (!value.startsWith("8")) return `8${inputObj}`;
            // }}
			mask={['_','_','_','_','_','_','_','_','_','_','_' ]}
		/>
	);
}

PhoneFormatCustom.propTypes = {
	inputRef: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default function OfferPhoneField({
	name,
	label,
	required,
	visible = true,
	type = "text",
	error = false,
	helperText = "",
}) {
	const classes = useStyles();
	const { control } = useFormContext();

	return (
		<OfferMaskField
			name={name}
			label={label}
			required={required}
			visible={visible}
			type={type}
			error={error}
			helperText={helperText}
			formatCustom={PhoneFormatCustom}
		/>
	);
}
