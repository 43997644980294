import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	Grid,
	Select,
	InputLabel,
	FormControl,
	MenuItem,
	FormHelperText,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({}));

export default function OfferSelectField({
	name,
	label,
	required,
	options,
	visible,
	value = "",
	onChange = () => {},
	error = false,
	helperText = "",
}) {
	const classes = useStyles();

	if (!options) options = [];

	return (
		<Grid item xs={12} style={{ display: visible ? "inherit" : "none" }}>
			<FormControl fullWidth required={required}>
				<InputLabel>{label}</InputLabel>
				<Select
					fullWidth
					name={name}
					required={required}
					value={value}
					onChange={onChange}
					error={error}
				>
					{options.map((option) => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</Select>
				<FormHelperText>{helperText}</FormHelperText>
			</FormControl>
		</Grid>
	);
}
