import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import OfferFrame from "../OfferFrame";
import CertificateFrame from "../CertificateFrame";
import SuccessFrame from "../SuccessFrame";
import AgreementFrame from "../AgreementFrame";

import "./style.css";
import { YMInitializer } from "react-yandex-metrika";

function App() {
	return (
		<>
			<YMInitializer
				accounts={[52951231]}
				options={{
					clickmap: true,
					trackLinks: true,
					accurateTrackBounce: true,
					webvisor: true,
				}}
			/>
			<Router>
				<Switch>
					<Route path="/agreement">
						<AgreementFrame />
					</Route>
					<Route path="/success">
						<SuccessFrame />
					</Route>
					<Route path="/certificate">
						<CertificateFrame />
					</Route>
					<Route path="/">
						<OfferFrame />
					</Route>
				</Switch>
			</Router>
		</>
	);
}

export default App;
