import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useFormContext, Controller } from "react-hook-form";
import { Grid, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({}));

export default function OfferMaskField({
	name,
	label,
	required,
	visible = true,
	type = "text",
	error = false,
	helperText = "",
    formatCustom
}) {
	const classes = useStyles();
	const { control } = useFormContext();

	return (
		<Grid item xs={12} style={{ display: visible ? "inherit" : "none" }}>
			<Controller
				name={name}
				control={control}
				defaultValue={type == "number" ? 1 : ""}
				render={({ field }) => (
					<TextField
						{...field}
						error={error}
						fullWidth
						label={label}
						type={type}
						required={required}
						helperText={helperText}
						InputProps={{
							inputComponent: formatCustom,
						}}
					/>
				)}
			/>
		</Grid>
	);
}
