import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, FormProvider } from "react-hook-form";
import {
	Grid,
	MobileStepper,
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	IconButton,
	Tooltip,
} from "@material-ui/core";
import OfferTextField from "../OfferTextField";
import OfferSelectField from "../OfferSelectField";
import OfferCheckboxField from "../OfferCheckboxField";
import OfferPhoneField from "../OfferPhoneField";
import {
	KeyboardArrowLeft,
	KeyboardArrowRight,
	PhoneCallback,
	WhatsApp,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
	buttonsContainer: {
		marginTop: theme.spacing(1),
		background: "#ffffff00",
		[theme.breakpoints.down(400)]: {
			padding: theme.spacing(1, 0, 1, 0),
		},
	},
	button: {
		[theme.breakpoints.down(400)]: {
			fontSize: "0.8rem",
		},
		[theme.breakpoints.down(370)]: {
			fontSize: "0.75rem",
		},
		[theme.breakpoints.down(350)]: {
			fontSize: "0.63rem",
		},
	},
	icon: {
		[theme.breakpoints.down(400)]: {
			width: "0.7em",
		},
		// [theme.breakpoints.down(370)]: {
		// 	fontSize: "0.9em",
		// },
	},
}));

function formatSum(sum) {
	let str = `${sum}`;
	let resStr = "";
	for (let ii = str.length - 1; ii >= 0; ii--) {
		let num = str.length - ii - 1;
		if (num > 0 && num % 3 == 0) resStr = `${str[ii]}'${resStr}`;
		else resStr = `${str[ii]}${resStr}`;
	}
	return resStr;
}

const emailRegex =
	/^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

function isEmailValid(email) {
	if (!email) return false;

	if (email.length > 254) return false;

	var valid = emailRegex.test(email);
	if (!valid) return false;

	var parts = email.split("@");
	if (parts[0].length > 64) return false;

	var domainParts = parts[1].split(".");
	if (
		domainParts.some(function (part) {
			return part.length > 63;
		})
	)
		return false;

	return true;
}

function openWhatsApp() {
	window.open("https://wa.me/79777704733", "_blank");
}

export default function OfferForm({
	formData,
	submitFormCall,
	submitFormPay,
	selectedData,
	errorMessage,
	closeError,
}) {
	const classes = useStyles();
	const methods = useForm();

	const [showErrors, setShowErrors] = useState(false);
	const [selectedDateID, setSelectedDateID] = useState("");
	// const [selectedDate, setSelectedDate] = useState("");
	const [selectedTicket, setSelectedTicket] = useState("1");

	useEffect(() => {
		if (selectedData.name) {
			methods.setValue("name", selectedData.name);
			methods.setValue("count", selectedData.count);
			methods.setValue("email", selectedData.email);
			methods.setValue("phone", selectedData.phone);
			// setSelectedDate(`${selectedData.date}|##|${selectedData.place}`);
			setSelectedDateID(`${selectedData.dateID}`);
			// setSelectedTicket(`${selectedData.ticket}`);
		}
		setSelectedTicket(`1`);

		let urlParams = new URLSearchParams(document.location.search);
		if (urlParams.get("transparent") == "") {
			document.body.classList.add("transparent");
		}
	}, []);

	const dateOptions = formData.games.map((game) => ({
		value: `${game.id}`,
		label: `${game.date.split(".").reverse().join(".")} ${game.openTime} — ${
			game.name
		}`,
	}));

	let dateObj = undefined;
	if (selectedDateID)
		dateObj = formData.games.find((game) => game.id == selectedDateID);

	let ticketObj = undefined;
	let ticketOptions = [];
	if (dateObj) {
		ticketOptions = dateObj.tickets.map((ticket) => ({
			value: `${ticket.id}`,
			label:
				ticket.price > 0
					? `${ticket.name} — ${ticket.price}₽`
					: `${ticket.name}`,
		}));

		if (selectedTicket)
			ticketObj = dateObj.tickets.find(
				(ticket) => ticket.id == parseInt(selectedTicket)
			);
	}

	const validateForm = (data, event) => {
		console.log(event);
		let { count, email, name, phone } = methods.getValues();

		// let source = selectedSource;
		let date = "";
		let place = "";
		let game = "";
		let dateID = selectedDateID;
		let ticket = parseInt(selectedTicket);
		if (dateObj) {
			date = dateObj.date;
			place = dateObj.placeID;
			game = dateObj.gameID;
		}

		if (
			place &&
			ticket &&
			count > 0 &&
			isEmailValid(email) &&
			name &&
			`${phone}`.length == 11 &&
			date
		) {
			setShowErrors(false);
			// console.log({
			// 	count,
			// 	email,
			// 	name,
			// 	phone,
			// 	date,
			// 	place,
			// 	ticket,
			// });
			if (event == "pay")
				submitFormPay({
					count,
					email,
					name,
					phone,
					date,
					place,
					game,
					ticket,
					dateID,
				});
			else
				submitFormCall({
					count,
					email,
					name,
					phone,
					date,
					place,
					game,
					ticket,
					dateID,
				});
		} else setShowErrors(true);
	};

	const onSubmitPay = methods.handleSubmit((data) => {
		validateForm(data, "pay");
	});
	const onSubmitCall = methods.handleSubmit((data) => {
		validateForm(data, "call");
	});

	return (
		<>
			<Dialog
				open={errorMessage != ""}
				onClose={closeError}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{errorMessage}</DialogTitle>
				<DialogActions>
					<Button onClick={closeError} color="primary" autoFocus>
						Закрыть
					</Button>
				</DialogActions>
			</Dialog>
			<FormProvider {...methods}>
				<form>
					<Grid container spacing={2}>
						<OfferSelectField
							name="date"
							label="Дата"
							options={dateOptions}
							visible
							required
							helperText={(dateObj && dateObj.address) || ""}
							value={selectedDateID}
							onChange={(e) => {
								setSelectedDateID(e.target.value);
							}}
							error={showErrors && !selectedDateID}
						/>
						{/* <OfferSelectField
							name="ticket"
							label="Билет"
							options={ticketOptions}
							visible={selectedDateID}
							required
							value={selectedTicket}
							onChange={(e) => {
								setSelectedTicket(e.target.value);
							}}
							error={showErrors && !selectedTicket}
						/> */}
						<OfferTextField
							name="name"
							label="ФИО"
							required
							error={showErrors && !methods.watch("name")}
						/>
						<OfferPhoneField
							name="phone"
							label="Телефон"
							required
							error={showErrors && `${methods.watch("phone")}`.length != 11}
						/>
						{/* <OfferTextField
						name="phone"
						label="Телефон"
						required
						error={showErrors && !methods.watch("phone")}
					/> */}
						<OfferTextField
							name="email"
							label="E-mail"
							required
							error={showErrors && !isEmailValid(methods.watch("email"))}
						/>
						<OfferTextField
							name="count"
							label="Кол-во гостей"
							type="number"
							required
							helperText={
								(dateObj &&
									ticketObj &&
									methods.watch("count") > 0 &&
									ticketObj.price > 0 &&
									`К оплате ${formatSum(
										ticketObj.price * methods.watch("count")
									)}₽`) ||
								""
							}
							error={showErrors && methods.watch("count") < 1}
						/>
						{/* <OfferTextField name="field" label="Сфера деятельности" />
						<OfferTextField name="social" label="Аккаунт в соц сети" /> */}
						{/* <OfferSelectField
							name="source"
							label="Откуда узнали о нас"
							options={sourceOptions}
							visible
							required
							helperText=""
							value={selectedSource}
							onChange={(e) => {
								setSelectedSource(e.target.value);
								methods.setValue("source_other", "");
							}}
							error={showErrors && !selectedSource}
						/>
						<OfferTextField
							name="source_other"
							label="Свой вариант"
							visible={selectedSource == "other"}
							required
							error={
								showErrors &&
								selectedSource == "other" &&
								!methods.watch("source_other")
							}
						/> */}
						{/* <OfferCheckboxField
							name="agreement"
							label={
								<div>
									Я подтверждаю своё согласие с{" "}
									<a target="_blank" href="/agreement">
										Договором оказания услуг по организации мероприятия «Детская
										мафия»
									</a>
								</div>
							}
							checked={acceptAgreement}
							error={showErrors && !acceptAgreement}
							helperText={
								showErrors && !acceptAgreement && "Подтвердите своё согласие"
							}
							onChange={(event) => {
								setAcceptAgreement(event.target.checked);
							}}
						/> */}
					</Grid>
					<MobileStepper
						className={classes.buttonsContainer}
						variant="dots"
						steps={3}
						position="static"
						activeStep={0}
						nextButton={
							<Button
								className={classes.button}
								variant="outlined"
								size="small"
								name="pay"
								onClick={() => {
									onSubmitPay();
								}}
							>
								{ticketObj && ticketObj.price > 0 ? "К оплате" : "Записатсья"}{" "}
								<KeyboardArrowRight className={classes.icon} />
							</Button>
						}
						backButton={
							<div>
								<Tooltip title="WhatsApp">
									<IconButton
										className={classes.button}
										onClick={openWhatsApp}
										size="small"
										style={{ marginRight: "8px" }}
										aria-label="WhatsApp"
									>
										<WhatsApp className={classes.icon} />
									</IconButton>
								</Tooltip>
								<Button
									className={classes.button}
									variant="outlined"
									size="small"
									name="call"
									onClick={() => {
										onSubmitCall();
									}}
								>
									Заказать звонок
								</Button>
							</div>
						}
					/>
				</form>
			</FormProvider>
		</>
	);
}
