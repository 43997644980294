import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useFormContext, Controller } from "react-hook-form";
import { Grid, TextField, Checkbox, FormControlLabel, FormControl, FormGroup, FormHelperText } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({}));

export default function OfferCheckboxField({
	name,
	label,
	required,
	visible = true,
	error = false,
	checked = false,
	onChange = () => {},
	helperText = "",
}) {
	const classes = useStyles();
	const { control } = useFormContext();

	return (
		<Grid item xs={12} style={{ display: visible ? "inherit" : "none" }}>
			{/* <FormControlLabel
				error={error}
				required={required}
                onChange={onChange}
				control={<Checkbox checked={checked} name={name} />}
				label={label}
			/> */}
			<FormControl
				required={required}
				error={error}
				component="fieldset"
			>
				<FormGroup>
					<FormControlLabel
						control={
							<Checkbox checked={checked} onChange={onChange} name={name} />
						}
						label={label}
					/>
				</FormGroup>
				<FormHelperText>{helperText}</FormHelperText>
			</FormControl>
		</Grid>
	);
}
