import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button,MobileStepper } from "@material-ui/core";

import CardIcon from "../Icons/CardIcon";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
	cardIcon: {
		fontSize: theme.spacing(20),
		margin: "0 auto",
		display: "block",
	},
	buttonsContainer: {
		marginTop: theme.spacing(1),
		background: "#ffffff00",
	},
    bottomHint:{
        marginTop: theme.spacing(2),
    }
}));

export default function PayForm({ ticketData, ticketID }) {
	const classes = useStyles();

	const openPayPage = () => {
		window.open(ticketData.payment_url, "_blank");
	};

	return (
		<>
			<Typography variant="h6" align="center">
				Платеж №
			</Typography>
			<Typography variant="body1" align="center">
				{ticketID}
			</Typography>
			<CardIcon className={classes.cardIcon} />
			<Typography variant="h4" align="center" gutterBottom>
				{ticketData.amount}₽
			</Typography>
			{/* <Typography variant="h6" align="center" gutterBottom>
				Ожидание оплаты...
			</Typography> */}
			<Grid container direction="row" justify="center" alignItems="center">
				<Button size="large" variant="outlined" onClick={openPayPage}>
					Перейти к оплате
				</Button>
			</Grid>
			<Typography className={classes.bottomHint} variant="body2" align="center">
				Совершите оплату в течении 15 минут!
			</Typography>
            <Typography variant="body2" align="center">
				Не обновляйте страницу, форма обновится автоматически через 15-30 секунд после совершения платежа
			</Typography>
			<MobileStepper
				className={classes.buttonsContainer}
				variant="dots"
				steps={3}
				position="static"
				activeStep={1}
				nextButton={
					<Button
						variant="outlined"
						size="small"
						style={{ visibility: "hidden" }}
					>
						К оплате <KeyboardArrowRight />
					</Button>
				}
				backButton={
					<Button
						variant="outlined"
						size="small"
						style={{ visibility: "hidden" }}
					>
						<KeyboardArrowLeft /> Назад
					</Button>
				}
			/>
		</>
	);
}
