import { SvgIcon } from "@material-ui/core";

function CardIcon(props) {
	return (
		<SvgIcon {...props} viewBox="0 0 512 512">
			<path
				style={{ fill: "#2196F3" }}
				d="M53.333,85.333h405.333c29.455,0,53.333,23.878,53.333,53.333v234.667
	c0,29.455-23.878,53.333-53.333,53.333H53.333C23.878,426.667,0,402.789,0,373.333V138.667C0,109.211,23.878,85.333,53.333,85.333z"
			/>
			<rect
				y="149.333"
				style={{ fill: "#455A64" }}
				width="512"
				height="85.333"
			/>
			<g>
				<path
					style={{ fill: "#FAFAFA" }}
					d="M160,320H74.667C68.776,320,64,315.224,64,309.333s4.776-10.667,10.667-10.667H160
		c5.891,0,10.667,4.776,10.667,10.667S165.891,320,160,320z"
				/>
				<path
					style={{ fill: "#FAFAFA" }}
					d="M224,362.667H74.667C68.776,362.667,64,357.891,64,352c0-5.891,4.776-10.667,10.667-10.667H224
		c5.891,0,10.667,4.776,10.667,10.667C234.667,357.891,229.891,362.667,224,362.667z"
				/>
			</g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
		</SvgIcon>
	);
}

export default CardIcon;
