import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button, MobileStepper } from "@material-ui/core";

import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
	cardIcon: {
		fontSize: theme.spacing(20),
		margin: "0 auto",
		display: "block",
	},
	buttonsContainer: {
		marginTop: theme.spacing(1),
		background: "#ffffff00",
	},
	bottomHint: {
		marginTop: theme.spacing(2),
	},
}));

export default function CallForm() {
	const classes = useStyles();

	return (
		<>
			<Typography variant="h6" align="center" gutterBottom>
				Мы передали ваш запрос нашему менеджеру, как только он освободится -
				сразу же с вами свяжется.
			</Typography>
			<MobileStepper
				className={classes.buttonsContainer}
				variant="dots"
				steps={3}
				position="static"
				activeStep={2}
				nextButton={
					<Button
						variant="outlined"
						size="small"
						style={{ visibility: "hidden" }}
					>
						К оплате <KeyboardArrowRight />
					</Button>
				}
				backButton={
					<Button
						variant="outlined"
						size="small"
						style={{ visibility: "hidden" }}
					>
						<KeyboardArrowLeft /> Назад
					</Button>
				}
			/>
		</>
	);
}
