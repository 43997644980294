import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	appBar: {
		position: "relative",
	},
	toolbar: theme.mixins.toolbar,
	layout: {
		marginTop: "5%",
		width: "auto",
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		[theme.breakpoints.down("xs")]: {
			marginLeft: theme.spacing(0.5),
			marginRight: theme.spacing(0.5),
		},
		[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
			width: 600,
			marginLeft: "auto",
			marginRight: "auto",
		},
	},
	paper: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2, 2, 0, 2),
		[theme.breakpoints.down("xs")]: {
			padding: theme.spacing(2, 1, 0, 1),
			width: "auto",
			marginTop: 30,
		},
		[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
			marginTop: theme.spacing(6),
			marginBottom: theme.spacing(6),
			padding: theme.spacing(3, 3, 1, 3),
		},
	},
	stepper: {
		padding: theme.spacing(3, 0, 3),
    [theme.breakpoints.down("xs")]: {
			paddingLeft: 0,
			paddingRight: 0,
		},
	},
	buttons: {
		display: "flex",
		justifyContent: "flex-end",
	},
	button: {
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(1),
	},
	divider: {
		margin: "20px 0",
	},
	spinner: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	buttonsContainer: {
		marginTop: theme.spacing(1),
		background: "#ffffff00",
		[theme.breakpoints.down("xs")]: {
			padding: theme.spacing(1, 0, 1, 0),
		},
	},
}));
