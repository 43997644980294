import { Paper, Typography } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";

const AgreementFrame = () => {
	const classes = useStyles();

	return (
		<>
			<main className={classes.layout}>
				<Paper className={classes.paper}>
					<Typography variant="h6" align="center" gutterBottom>
						Договор оказания услуг по организации мероприятия «Детская мафия»
					</Typography>
					<Typography variant="body1" paragraph>
						Настоящий текст является публичной офертой Родыгин С.Е. ИНН
						771618426478 ОГРНИП 313774635401630 (далее – «Исполнитель»), т.е.
						предложением Исполнителя, являющегося организатором Мероприятия,
						указанного на соответствующей странице Сайта, заключить договор с
						любым Пользователем на указанных ниже условиях.
					</Typography>
					<Typography variant="body1">
						В соответствии с пунктом 3 статьи 438 Гражданского кодекса
						Российской Федерации, надлежащим акцептом настоящей оферты считается
						последовательное осуществление Пользователем следующих действий:
					</Typography>
					<Typography variant="body1">
						- ознакомление с условиями настоящей оферты;
					</Typography>
					<Typography variant="body1" paragraph>
						- введение регистрационных данных в анкете регистрации и
						подтверждение достоверности этих данных нажатием кнопки
						«Зарегистрироваться», «Купить билет» или аналога.
					</Typography>
					<Typography variant="body1" paragraph>
						С момента нажатия кнопки «Зарегистрироваться», «Купить билет» или
						аналога соответственно и в случае верного последовательного
						выполнения всех указанных выше действий, договор оказания услуг по
						проведению Мероприятия считается заключенным между Исполнителем и
						Пользователем.
					</Typography>
					<Typography variant="body1" paragraph>
						1. Предмет договора
					</Typography>
					<Typography variant="body1" paragraph>
						1.1. Исполнитель обязуется оказать услуги по организации и
						проведению Мероприятия в соответствии с информацией (включая
						название, описание, дату, время и место проведения), указанной на
						соответствующей странице в сети Интернет.
					</Typography>
					<Typography variant="body1" paragraph>
						1.2. Услуги оказываются Исполнителем на платной основе путем
						реализации Электронных билетов посредством сайта
						https://tickets.mafdrive.ru/ Стоимость Электронных билетов указаны
						на соответствующей странице в сети Интернет.
					</Typography>
					<Typography variant="body1" paragraph>
						1.3. Услуги оказываются Исполнителем такому Пользователю, который
						приобрел Электронный билет на Мероприятие Исполнителя.
					</Typography>
					<Typography variant="body1" paragraph>
						1.4. Услуги Исполнителя считаются оказанными в момент предъявления
						Электронного билета Исполнителю или его уполномоченному лицу при
						посещении держателем Электронного билета Мероприятия или в момент
						начала Мероприятия (для онлайн мероприятий).
					</Typography>
					<Typography variant="body1" paragraph>
						1.5. Все обязательства по поводу проведения Мероприятий,
						приобретения и возврата Электронного билета возникают между
						Пользователем и Исполнителем, за исключением случаев, прямо
						предусмотренных настоящей офертой.
					</Typography>
					<Typography variant="body1" paragraph>
						2. Персональные данные
					</Typography>
					<Typography variant="body1" paragraph>
						2.1. Пользователь дает Исполнителю свое согласие на обработку
						персональных данных, как без использования средств автоматизации,
						так и с их использованием.
					</Typography>
					<Typography variant="body1" paragraph>
						2.1.1. Фамилия, имя, отчество;
					</Typography>
					<Typography variant="body1" paragraph>
						2.1.2. Гражданство;
					</Typography>
					<Typography variant="body1" paragraph>
						2.1.3. Номера контактных телефонов;
					</Typography>
					<Typography variant="body1" paragraph>
						2.1.4. Адрес электронной почты;
					</Typography>
					<Typography variant="body1" paragraph>
						2.1.5. Сведения об образовании, повышении квалификации и
						переподготовке;
					</Typography>
					<Typography variant="body1" paragraph>
						2.1.6. Сведения об интересах;
					</Typography>
					<Typography variant="body1" paragraph>
						2.1.7. Сведения о посещаемых мероприятиях.
					</Typography>
					<Typography variant="body1" paragraph>
						2.2. Целями обработки персональных данных является исполнение
						Исполнителем условий настоящего договора, а также проведение
						рекламных кампаний и маркетинговых исследований. Условием
						прекращения обработки персональных данных может являться достижение
						целей обработки персональных данных, истечение срока действия
						согласия или отзыв согласия Пользователем, а также выявление
						неправомерной обработки персональных данных.
					</Typography>
					<Typography variant="body1" paragraph>
						2.3. В ходе обработки с персональными данными могут быть совершены
						следующие действия: сбор, запись, систематизация, накопление,
						хранение, уточнение (обновление, изменение), извлечение,
						использование, передача (распространение, предоставление, доступ),
						обезличивание, блокирование, удаление, уничтожение.
					</Typography>
					<Typography variant="body1" paragraph>
						2.4. Передача персональных данных третьим лицам осуществляется в
						соответствии с действующим законодательством Российской Федерации и
						соглашениями между Исполнителем и Пользователем.
					</Typography>
					<Typography variant="body1" paragraph>
						2.5. Персональные данные обрабатываются до прекращения деятельности
						Исполнителя. Хранение персональных данных осуществляется согласно
						Федеральному закону №125-ФЗ «Об архивном деле в Российской
						Федерации» и иным нормативно правовым актам в области архивного дела
						и архивного хранения.
					</Typography>
					<Typography variant="body1" paragraph>
						2.6. В случае отзыва Пользователем согласия на обработку
						персональных данных Исполнитель вправе продолжить обработку
						персональных данных без согласия Пользователя при наличии оснований,
						указанных в пунктах 2 – 11 части 1 статьи 6, части 2 статьи 10 и
						части 2 статьи 11 Федерального закона №152-ФЗ «О персональных
						данных» от 27.07.2006 г.
					</Typography>
					<Typography variant="body1" paragraph>
						3. Возврат Электронного билета
					</Typography>
					<Typography variant="body1" paragraph>
						3.1. Возврат Электронного билета осуществляется Исполнителем в
						сроки, установленные законодательством России, в том числе, если
						законодательством не установлены иные способы возврата, в следующем
						порядке:
					</Typography>
					<Typography variant="body1">
						3.1.1. Возврат билетов на мероприятия, проводимые Исполнителями
						осуществляется в следующем порядке (если иной порядок не
						предусмотрен законодательством России):
					</Typography>
					<Typography variant="body1">
						- Исполнитель отменил или перенес мероприятие по своей инициативе,
						то осуществляется полный возврат стоимости электронного билета или
						предложение перенести мероприятие на другую дату;
					</Typography>
					<Typography variant="body1">
						- Пользователь направил соответствующее требование не менее, чем за
						10 дней до начала Мероприятия, осуществляется возврат 100% стоимости
						электронного билета;
					</Typography>
					<Typography variant="body1">
						- Пользователь направил соответствующее требование в срок от 5 до 10
						дней до начала Мероприятия, осуществляется возврат 50% стоимости
						электронного билета;
					</Typography>
					<Typography variant="body1">
						- Пользователь направил соответствующее требование в срок от 3 до 5
						дней до начала Мероприятия, осуществляется возврат 30% стоимости
						электронного билета;
					</Typography>
					<Typography variant="body1" paragraph>
						- Пользователь направил соответствующее требование в срок менее 3 дней до начала Мероприятия, возврат стоимости электронного билета не осуществляется.
					</Typography>
					<Typography
						variant="body1"
						gutterBottom
                        paragraph
						style={{ marginBottom: "20px" }}
					>
						3.2. В указанных выше случаях требование о возврате оформляется Пользователем в виде электронного письма на адрес games@mafdrive.ru
					</Typography>
				</Paper>
			</main>
		</>
	);
};

export default AgreementFrame;
