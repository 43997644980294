import { Paper, Typography } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";

const SuccessFrame = () => {
	const classes = useStyles();

	return (
		<>
			<main className={classes.layout}>
				<Paper className={classes.paper}>
					<Typography variant="h4" align="center" gutterBottom>
						Оплата прошла успешно
					</Typography>
					<Typography variant="h6" align="center" gutterBottom style={{ marginBottom: "20px" }}>
						Данную страницу можно закрыть
					</Typography>
				</Paper>
			</main>
		</>
	);
};

export default SuccessFrame;
