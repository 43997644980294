import { Paper, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import Loader from "../Loader";
import OfferForm from "../OfferForm";
import PayForm from "../PayForm";
import TicketForm from "../TicketForm";
import CallForm from "../CallForm";
import ym from "react-yandex-metrika";

async function createTicket(data) {
	const res = await axios.post(
		"https://tickets.mafdrive.ru/api/form/create_ticket",
		data
	);
	console.log(res.data);
	return res.data;
}

async function createCall(data) {
	const res = await axios.post(
		"https://tickets.mafdrive.ru/api/form/create_call",
		data
	);
	console.log(res.data);
	return res.data;
}

async function getTicketData(ticketUUID) {
	const res = await axios.get(
		`https://tickets.mafdrive.ru/api/form/ticket/${ticketUUID}`
	);
	console.log(res.data);
	return res.data;
}

function declOfNum(n, titles) {
	return titles[
		n % 10 === 1 && n % 100 !== 11
			? 0
			: n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
			? 1
			: 2
	];
}

const OfferFrame = () => {
	const classes = useStyles();

	const [activeStep, setActiveStep] = useState(0);
	const [updatingData, setUpdatingData] = useState(false);
	const [formData, setFormData] = useState({});
	const [ticketData, setTicketData] = useState({});
	const [selectedData, setSelectedData] = useState({});
	const [loading, setLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState("");

	const submitFormPay = async (data) => {
		setLoading(true);
		setSelectedData(data);
		try {
			let ticketDataRes = await createTicket(data);
			if (!ticketDataRes.success) {
				if (ticketDataRes.error) {
					let errorArray = ticketDataRes.error.split("#");
					let errorName = errorArray[0];
					if (errorName == "Place Reserved" && errorArray.length > 1) {
						let placesVacant = parseInt(errorArray[1]);
						setErrorMessage(
							placesVacant > 0
								? `Сожалеем, на эту дату осталось ${placesVacant} ${declOfNum(
										placesVacant,
										["место", "места", "мест"]
								  )}.`
								: `Сожалеем, все места проданы. Рекомендуем выбрать другую дату.`
						);
					} else {
						setErrorMessage("Произошла неизвестная ошибка, попробуйте снова");
					}
				}
				throw "Ticket Create Failed";
			}
			setTicketData(ticketDataRes.data);
			if (ticketDataRes.data.paid) setActiveStep(2);
			else setActiveStep(1);
			setLoading(false);
			setUpdatingData(true);
			ym("reachGoal", "RG-v1-CashFlow-Form");
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	const submitFormCall = async (data) => {
		setLoading(true);
		setSelectedData(data);
		try {
			let ticketDataRes = await createCall(data);
			if (!ticketDataRes.success) {
				if (ticketDataRes.error) {
					setErrorMessage("Произошла неизвестная ошибка, попробуйте снова");
				}
				throw "Call Create Failed";
			}
			setActiveStep(3);
			setLoading(false);
			setUpdatingData(true);
			ym("reachGoal", "RG-v1-CashFlow-Form");
			// ym(52951231, "reachGoal", "RG-v1-CashFlow-Form");
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	const updateTicketData = async () => {
		if (ticketData && ticketData.uuid) {
			let newTicketData = await getTicketData(ticketData.uuid);
			if (newTicketData && newTicketData.ticket) {
				setTicketData(newTicketData.ticket);
				if (newTicketData.ticket.paid) {
					setActiveStep(2);
					setUpdatingData(false);
				}
			}
		}
	};

	const closeError = () => {
		setErrorMessage("");
	};

	const Form = () => {
		if (loading) {
			return <Loader />;
		} else {
			switch (activeStep) {
				case 0:
					return (
						<OfferForm
							formData={formData}
							selectedData={selectedData}
							submitFormPay={submitFormPay}
							submitFormCall={submitFormCall}
							closeError={closeError}
							errorMessage={errorMessage}
						/>
					);
				case 1:
					return <PayForm ticketData={ticketData} />;
				case 2:
					return (
						<TicketForm
							ticketData={ticketData}
							ticketID={ticketData.display_id}
						/>
					);
				case 3:
					return <CallForm />;
				default:
					return (
						<OfferForm
							formData={formData}
							selectedData={selectedData}
							submitFormPay={submitFormPay}
							submitFormCall={submitFormCall}
							closeError={closeError}
							errorMessage={errorMessage}
						/>
					);
			}
		}
	};

	useEffect(() => {
		axios.get("https://tickets.mafdrive.ru/api/form/cashflow").then((res) => {
			setFormData(res.data);
			setLoading(false);
		});
	}, []);

	useEffect(() => {
		let interval;
		if (updatingData)
			interval = setInterval(async () => {
				await updateTicketData();
			}, 5000);
		return () => {
			if (interval) clearInterval(interval);
			console.log("Stop:", updatingData);
		};
	}, [updatingData]);

	return (
		<>
			<main className={classes.layout}>
				<Paper className={classes.paper}>
					<Typography variant="h4" align="center" gutterBottom>
						Запись на игру
					</Typography>
					<Form />
				</Paper>
			</main>
		</>
	);
};

export default OfferFrame;
