import { Paper, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import Loader from "../Loader";
import CertificateOfferForm from "../CertificateOfferForm";
import PayForm from "../PayForm";
import CertificateForm from "../CertificateForm";
import CallForm from "../CallForm";

async function createCertificate(data) {
	const res = await axios.post(
		"https://tickets.mafdrive.ru/api/form/create_certificate",
		data
	);
	console.log(res.data);
	return res.data;
}

async function getCertificateData(certificateUUID) {
	const res = await axios.get(
		`https://tickets.mafdrive.ru/api/form/certificate/${certificateUUID}`
	);
	console.log(res.data);
	return res.data;
}

function declOfNum(n, titles) {
	return titles[
		n % 10 === 1 && n % 100 !== 11
			? 0
			: n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
			? 1
			: 2
	];
}

const CertificateFrame = () => {
	const classes = useStyles();

	const [activeStep, setActiveStep] = useState(0);
	const [updatingData, setUpdatingData] = useState(false);
	const [formData, setFormData] = useState({});
	const [certificateData, setCertificateData] = useState({});
	const [selectedData, setSelectedData] = useState({});
	const [loading, setLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState("");

	const submitFormPay = async (data) => {
		setLoading(true);
		setSelectedData(data);
		try {
			let certificateDataRes = await createCertificate(data);
			if (!certificateDataRes.success) {
				if (certificateDataRes.error) {
					let errorArray = certificateDataRes.error.split("#");
					let errorName = errorArray[0];
					if (errorName == "Place Reserved" && errorArray.length > 1) {
						let placesVacant = parseInt(errorArray[1]);
						setErrorMessage(
							placesVacant > 0
								? `Сожалеем, на эту дату осталось ${placesVacant} ${declOfNum(
										placesVacant,
										["место", "места", "мест"]
								  )}.`
								: `Сожалеем, все места проданы. Рекомендуем выбрать другую дату.`
						);
					} else if (errorName == "Incorrect Promo") {
						setErrorMessage(`Сожалеем, промокод недействителен`);
					} else {
						setErrorMessage("Произошла неизвестная ошибка, попробуйте снова");
					}
				}
				throw "Certificate Create Failed";
			}
			setCertificateData(certificateDataRes.data);
			if (certificateDataRes.data.paid) setActiveStep(2);
			else setActiveStep(1);
			setLoading(false);
			setUpdatingData(true);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	const updateCertificateData = async () => {
		if (certificateData && certificateData.certificate_uuid) {
			let newCertificateData = await getCertificateData(certificateData.certificate_uuid);
			if (newCertificateData && newCertificateData.certificate) {
				setCertificateData(newCertificateData.certificate);
				if (newCertificateData.certificate.paid) {
					setActiveStep(2);
					setUpdatingData(false);
				}
			}
		}
	};

	const closeError = () => {
		setErrorMessage("");
	};

	const Form = () => {
		if (loading) {
			return <Loader />;
		} else {
			switch (activeStep) {
				case 0:
					return (
						<CertificateOfferForm
							formData={formData}
							selectedData={selectedData}
							submitFormPay={submitFormPay}
							closeError={closeError}
							errorMessage={errorMessage}
						/>
					);
				case 1:
					return <PayForm ticketData={certificateData} ticketID={certificateData.certificate_id} />;
				case 2:
					return <CertificateForm certificateData={certificateData} />;
				case 3:
					return <CallForm />;
				default:
					return (
						<CertificateOfferForm
							formData={formData}
							selectedData={selectedData}
							submitFormPay={submitFormPay}
							closeError={closeError}
							errorMessage={errorMessage}
						/>
					);
			}
		}
	};

	useEffect(() => {
		axios.get("https://tickets.mafdrive.ru/api/form/certificate").then((res) => {
			setFormData(res.data);
			setLoading(false);
		});
	}, []);

	useEffect(() => {
		let interval;
		if (updatingData)
			interval = setInterval(async () => {
				await updateCertificateData();
			}, 5000);
		return () => {
			if (interval) clearInterval(interval);
			console.log("Stop:", updatingData);
		};
	}, [updatingData]);

	return (
		<>
			<main className={classes.layout}>
				<Paper className={classes.paper}>
					<Typography variant="h4" align="center" gutterBottom>
						Подарочный сертификат
					</Typography>
					<Form />
				</Paper>
			</main>
		</>
	);
};

export default CertificateFrame;
